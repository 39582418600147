import type { ParsedUrlQuery } from 'querystring';

import type { NextPage } from 'next';
import Head from 'next/head';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useEffect, useRef } from 'react';
import type { ConditionalKeys } from 'type-fest';

import * as Routes from '../constants/routes';

type RouteKey = ConditionalKeys<typeof Routes, string>;

/**
 * Creates a Next.js Page component that handles redirecting to another page.
 *
 * You can pass it either a destination path, or a function that will take
 * the current query parameters and return the destination path.
 *
 * This function will handle both client-side and (if possible) server-side redirection.
 */

export function makeRedirectPage(destination: RouteKey): NextPage;
export function makeRedirectPage<Query extends ParsedUrlQuery>(
  destinationFn: (router: NextRouter & { query: Query }) => string
): NextPage;

export function makeRedirectPage(
  destinationArg: RouteKey | ((router: NextRouter) => string)
): NextPage {
  const destination =
    typeof destinationArg === 'string'
      ? // Disabling the eslint rule because it trips up when using an import as an object
        // eslint-disable-next-line import/namespace
        { str: Routes[destinationArg] }
      : { fn: destinationArg };

  const RedirectPage = () => {
    const router = useRouter();

    // Client-side redirection
    const isRedirecting = useRef(false);
    useEffect(() => {
      if (isRedirecting.current) return;
      isRedirecting.current = true;

      router.replace(destination.str ?? destination.fn(router));
    }, [router]);

    // Workaround for prerendered server-side redirection
    // (no need to wait for JS load)
    // but we can only do it when we don't depend on the
    // router's `query`
    return destination.str ? (
      <Head>
        <meta httpEquiv="refresh" content={`0;url=${destination.str}`} />
      </Head>
    ) : null;
  };

  // This page only exists for redirect, so we don't need a real layout for it
  RedirectPage.Layout = ({ children }: { children?: ReactNode }) => <>{children}</>;

  return RedirectPage;
}
